export const formatAddressForDisplay = ({ address, showAddressOne = false}) => {
  let formattedAddress = '';

  if (address.address_one && showAddressOne) {
    formattedAddress += `${address.address_one}, `;
  }

  formattedAddress += `${address.city}, ${address.state.code}`;

  return formattedAddress;
};