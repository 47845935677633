import axios from 'axios';
import { Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';

import '../../css/forms/OrganizationsSearchForm.css';

function OrganizationsSearchForm() {
  const [organizations, setOrganizations] = useState([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    loadingOrganizations && fetchOrganizations();
  }, [organizations]);

  function fetchOrganizations() {
    axios.get(`/api/v1/organizations`).then(
      (response) => {
        setOrganizations(response.data);
      },
      () => {
        setOrganizations([]);
      }
    );
    setLoadingOrganizations(false);
  }

  return (
    <Form className="organizations-search-form">
      <Row>
        <Col>
          <Typeahead
            id="organization-search"
            labelKey="name"
            onChange={(selected) => navigate(`/organizations/${selected[0].id}/jobs`)}
            options={organizations}
            placeholder="Search communities"
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
              return (
                <>
                  <FloatingLabel controlId="organization-search" label="Search communities">
                    <Form.Control
                      {...inputProps}
                      ref={(node) => {
                        inputRef(node);
                        referenceElementRef(node);
                      }}
                    />
                    <i className="inline-icon p-2 fa fa-search" />
                  </FloatingLabel>
                </>
              );
            }}
            renderMenuItemChildren={(option, props, index) => {
              return (
                <>
                  <div className="d-flex mb-2 align-items-center item">
                    {option?.logo &&
                      <div className="me-3">
                        <img className="sm-icon-img" src={option.logo} />
                      </div>
                    }
                    <div >
                      <span><strong>{option.name}</strong></span>
                      <div>
                        <small>{option.tagline}</small>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default OrganizationsSearchForm;