
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';

import { GetFeedbackMessageFor } from '../../utils/FormUtils';
import ImageUploadInput from '../inputs/ImageUploadInput';
import AddressForm from '../forms/AddressForm';

const RewardSourceForm = ({ rewardSource }) => {
  const [regions, setRegions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [addressVisible, setAddressVisible] = useState(false);
  const [hasAddress, setHasAddress] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    active: false,
    approved: false,
    url: '',
    description: '',
    address_attributes: null
  });

  const navigate = useNavigate();

  useEffect(() => {
    let hasAddress = rewardSource?.address != undefined;
    setAddressVisible(hasAddress);
    setHasAddress(hasAddress);

    setFormData({
      ...formData,
      name: rewardSource?.name || '',
      region_id: rewardSource?.region?.id || '',
      active: rewardSource?.active || false,
      approved: rewardSource?.approved || false,
      url: rewardSource?.url || '',
      description: rewardSource?.description || '',
      address_attributes: hasAddress ? getAddressAttributes() : null
    });

    axios.get('/api/v1/regions')
      .then((response) => {
        setRegions(response.data);
      });
  }, [rewardSource]);

  function handleSaveAddressChange(e) {
    setAddressVisible(e.target.checked);
    setFormData({ ...formData, address_attributes: e.target.checked ? getAddressAttributes() : null });
  };

  function getAddressAttributes() {
    return {
      address_one: rewardSource?.address?.address_one || '',
      address_two: rewardSource?.address?.address_two || '',
      city: rewardSource?.address?.city || '',
      state_id: rewardSource?.address?.state?.id || '',
      zip: rewardSource?.address?.zip || ''
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    const finalFormData = new FormData(e.target);
    for (const key in formData) {
      if (key === 'address_attributes') {
        const addressData = formData[key];
        for (const addressKey in addressData) {
          finalFormData.append(`reward_source[${key}][${addressKey}]`, addressData[addressKey] ?? "");
        }
      } else {
        finalFormData.append(`reward_source[${key}]`, formData[key] ?? "");
      }
    }

    const request = rewardSource?.id ?
      axios.put(`/api/v1/reward_sources/${rewardSource.id}`, finalFormData) :
      axios.post(`/api/v1/reward_sources`, finalFormData);

    request
      .then((response) => {
        navigate('/admin/reward_sources');
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-1 mt-3" controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              name="reward_source[name]"
              placeholder="Enter name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              isInvalid={errors['name']}
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'name')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Form.Group className="mb-1 mt-3" controlId="region_id">
            <Form.Label>Region</Form.Label>
            <Form.Select
              name="reward_source[region_id]"
              value={formData.region_id}
              onChange={(e) => setFormData({ ...formData, region_id: e.target.value })}
              isInvalid={errors['region_id']}
            >
              <option value="">Select region</option>
              {regions.map((region) => (
                <option key={region.id} value={region.id}>{region.name}</option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'region_id')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      {!hasAddress &&
        <Form.Group className="mb-1 mt-3" controlId="save-address">
          <Form.Label>Save Address</Form.Label>
          <Form.Check type="switch" checked={addressVisible} onChange={(e) => handleSaveAddressChange(e)} />
        </Form.Group>
      }
      {addressVisible && <AddressForm errorMessage={errors} {...{ formData, setFormData }} />}

      <Row>
        <Col md={4}>
          <Form.Group className="mb-1 mt-1" controlId="icon" >
            <Form.Label>Icon</Form.Label>
            <Col sm={12}>
              <ImageUploadInput
                type='logo'
                hidden={false}
                onChange={(e) => setFormData({ ...formData, icon: e.target.files[0] })}
                value={formData.icon}
                currentImage={rewardSource?.icon}
                isInvalid={errors["icon"]}
                errorMessage={GetFeedbackMessageFor(errors, "icon")}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group className="mb-1 mt-3" controlId="name">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              name="reward_source[url]"
              placeholder="Enter URL"
              value={formData.url}
              onChange={(e) => setFormData({ ...formData, url: e.target.value })}
              isInvalid={errors['url']}
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'url')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Form.Group className="mb-1 mt-3" controlId="name">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="2"
              name="reward_source[description]"
              placeholder="Enter description"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              isInvalid={errors['description']}
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'description')}</Form.Control.Feedback>
            <small className="text-muted">Maximum 1000 characters</small>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Form.Group className="mb-1 mt-3" controlId="approved">
          <Form.Check
            type="checkbox"
            name="reward_source[approved]"
            label="Approved"
            className="standard-checkbox"
            checked={formData.approved}
            onChange={(e) => setFormData({ ...formData, approved: e.target.checked })}
          />
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-4 mt-3" controlId="active">
          <Form.Check
            type="checkbox"
            name="reward_source[active]"
            label="Active"
            className="standard-checkbox"
            checked={formData.active}
            onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
          />
        </Form.Group>
      </Row>

      <Row>
        <Col>
          <Button style={{ width: '100px' }} type="submit" className="standard-btn" disabled={isSubmitting}>
            {isSubmitting ? <Spinner size="sm" /> : 'Save'}
          </Button>
          <Button style={{ width: '100px' }} type="button" as={Link} to={'/admin/reward_sources'} className="ms-2 light-btn">
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default RewardSourceForm;