import { useSelector } from 'react-redux';

import EmployersResolveJobModal from './EmployersResolveJobModal';
import ForgotPasswordModal from './ForgotPasswordModal.js';
import JobsFiltersModal from './JobsFiltersModal.js';
import LoginModal from './LoginModal.js';
import RewardMarketplaceModal from './RewardMarketplaceModal.js';
import SignUpModal from './SignUpModal.js';
import ShareJobModal from './ShareJobModal.js';

function ModalManager() {
  const modalData = useSelector((state) => state.modalData);
  const MODAL_TYPES = {
    EmployersResolveJobModal,
    ForgotPasswordModal,
    JobsFiltersModal,
    LoginModal,
    RewardMarketplaceModal,
    SignUpModal,
    ShareJobModal
  };

  let ModalComponent;
  if (modalData.modalType) {
    ModalComponent = MODAL_TYPES[modalData.modalType];
  }
  else {
    ModalComponent = undefined;
  }

  return (
    <>
      {ModalComponent ? <ModalComponent {...modalData.modalProps} /> : null}
    </>
  );
}

export default ModalManager;