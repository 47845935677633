import { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Loading from '../Loading';
import OrganizationTableEntry from './OrganizationTableEntry';

const Organizations = () => {
  const [organizations, setOrganizations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios.get('/api/v1/admin/organizations')
      .then((response) => {
        setOrganizations(response.data);
      })
      .catch((error) => {
        setErrorMessage('Error loading organizations.');
      })
      .finally(() => {
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <Container className="pt-3">
      <Row>
        <Col md={6}>
          <h3>Organizations</h3>
        </Col>
        <Col md={6} className="text-end">
          <Button className="standard-btn" as={Link} to={'/admin/organizations/new'}>New Organization</Button>
        </Col>
      </Row>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {errorMessage ?
            <Row className="mt-3">
              <Col>
                <Alert variant="danger">{errorMessage}</Alert>
              </Col>
            </Row>
            :
            <>
              {organizations.length === 0 ?
                <Row className="mt-3">
                  <Col>
                    <Alert variant="warning">There are no organizations yet.</Alert>
                  </Col>
                </Row>
                :
                <div className="table-responsive table-scrollable table-sticky-header">
                  <Table hover>
                    <thead>
                      <tr className="p-2">
                        <th>ID</th>
                        <th>Name</th>
                        <th className="text-center">Users</th>
                        <th className="text-center">Businesses</th>
                        <th className="text-center">Active</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {organizations.map((organization) => (
                        <OrganizationTableEntry key={organization.id} organization={organization} />
                      ))}
                    </tbody>
                  </Table>
                </div>
              }
            </>
          }
        </>
      )}
    </Container>
  );
}

export default Organizations;