import { useParams } from 'react-router-dom';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useApiGet from '../../hooks/useApiGet';
import OrganizationForm from './OrganizationForm';

const EditOrganization = () => {
  const params = useParams();
  const organization = useApiGet({url: `/api/v1/admin/organizations/${params.id}`}).data;

  return (
    <Container>
      <Col className="my-3">
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/organizations' }}>Organizations</Breadcrumb.Item>
              <Breadcrumb.Item active>{organization?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col sm={12}>
            <OrganizationForm organization={organization} />
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default EditOrganization;