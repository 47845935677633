import { Container, Row, Col, Button, Table, Alert } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Loading from '../Loading';
import RewardSourceTableEntry from './RewardSourceTableEntry';

const RewardSources = () => {
  const [rewardSources, setRewardSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios.get('/api/v1/reward_sources')
      .then((response) => {
        setRewardSources(response.data);
      })
      .catch((error) => {
        setErrorMessage('Error loading reward sources.');
      })
      .finally(() => {
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <Container className="pt-3">
      <Row>
        <Col md={6}>
          <h3>Reward Sources</h3>
        </Col>
        <Col md={6} className="text-end">
          <Button className="standard-btn" as={Link} to={'/admin/reward_sources/new'}>New Reward Source</Button>
        </Col>
      </Row>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {errorMessage ?
            <Row className="mt-3">
              <Col>
                <Alert variant="danger">{errorMessage}</Alert>
              </Col>
            </Row>
            :
            <>
              {rewardSources.length === 0 ?
                <Row className="mt-3">
                  <Col>
                    <Alert variant="warning">There are no reward sources yet.</Alert>
                  </Col>
                </Row>
                :
                <div className="table-responsive table-scrollable table-sticky-header">
                  <Table hover>
                    <thead>
                      <tr className="p-2">
                        <th>ID</th>
                        <th>Icon</th>
                        <th>Name</th>
                        <th>Region</th>
                        <th className="text-center">Approved</th>
                        <th className="text-center">Active</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {rewardSources.map((rewardSource) => (
                        <RewardSourceTableEntry key={rewardSource.id} rewardSource={rewardSource} />
                      ))}
                    </tbody>
                  </Table>
                </div>
              }
            </>
          }
        </>
      )}
    </Container>
  );
}

export default RewardSources;