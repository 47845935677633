import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { closeModal } from 'src/actions/modalActions';
import RewardMarketplace from 'src/components/rewardSources/RewardMarketplace';

function RewardMarketplaceModal({ ...props }) {
  const dispatch = useDispatch();

  function hide() {
    dispatch(closeModal());
  };

  return (
    <div>
      <Modal size={"lg"} show={true} onHide={e => hide()} fullscreen="md-down">
        <Modal.Header className="px-4" closeButton>
          <div><span className="fw-bold tertiary-mw-text">Reward Marketplace</span> | Job referrals meet community</div>
        </Modal.Header>
        <Modal.Body>
          <RewardMarketplace hideModal={hide} {...props} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default RewardMarketplaceModal;